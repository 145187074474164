define("ember-side-menu/components/side-menu-link-to", ["exports", "ember"], function (exports, _ember) {
    var get = _ember["default"].get;
    var service = _ember["default"].inject.service;
    var LinkComponent = _ember["default"].LinkComponent;
    exports["default"] = LinkComponent.extend({
        sideMenu: service(),

        click: function click() {
            get(this, "sideMenu").close();
        }
    });
});