define("ember-side-menu/utils/gestures", ["exports"], function (exports) {
    exports.calculateVelocity = calculateVelocity;
    exports.createGesture = createGesture;

    function calculateVelocity(start, end, deltaTime) {
        var delta = start - end;

        return delta / deltaTime;
    }

    function createGesture(startEvent, endEvent) {
        var time = endEvent.originalEvent.timeStamp - startEvent.originalEvent.timeStamp;
        var velocityX = calculateVelocity(startEvent.originalEvent.touches[0].pageX, endEvent.originalEvent.changedTouches[0].pageX, time);
        var velocityY = calculateVelocity(startEvent.originalEvent.touches[0].pageY, endEvent.originalEvent.changedTouches[0].pageY, time);

        return {
            time: time,
            velocityX: velocityX,
            velocityY: velocityY
        };
    }
});