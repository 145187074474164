enifed('ember-glimmer/component-managers/outlet', ['exports', 'ember-babel', '@glimmer/runtime', 'ember-metal', 'ember-utils', 'ember-glimmer/utils/references', 'ember-glimmer/component-managers/abstract'], function (exports, _emberBabel, _runtime, _emberMetal, _emberUtils, _references, _abstract) {
    'use strict';

    exports.OutletLayoutCompiler = exports.OutletComponentDefinition = exports.TopLevelOutletComponentDefinition = undefined;

    function instrumentationPayload(_ref) {
        var _ref$render = _ref.render,
            name = _ref$render.name,
            outlet = _ref$render.outlet;

        return { object: name + ':' + outlet };
    }
    function NOOP() {}

    var StateBucket = function () {
        function StateBucket(outletState) {

            this.outletState = outletState;
            this.instrument();
        }

        StateBucket.prototype.instrument = function () {
            this.finalizer = (0, _emberMetal._instrumentStart)('render.outlet', instrumentationPayload, this.outletState);
        };

        StateBucket.prototype.finalize = function () {
            var finalizer = this.finalizer;

            finalizer();
            this.finalizer = NOOP;
        };

        return StateBucket;
    }();

    var OutletComponentManager = function (_AbstractManager) {
        (0, _emberBabel.inherits)(OutletComponentManager, _AbstractManager);

        function OutletComponentManager() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _AbstractManager.apply(this, arguments));
        }

        OutletComponentManager.prototype.create = function (environment, definition, _args, dynamicScope) {
            var outletStateReference = dynamicScope.outletState = dynamicScope.outletState.get('outlets').get(definition.outletName);
            var outletState = outletStateReference.value();
            return new StateBucket(outletState);
        };

        OutletComponentManager.prototype.layoutFor = function (definition, _bucket, env) {
            return env.getCompiledBlock(OutletLayoutCompiler, definition.template);
        };

        OutletComponentManager.prototype.getSelf = function (_ref2) {
            var outletState = _ref2.outletState;

            return new _references.RootReference(outletState.render.controller);
        };

        OutletComponentManager.prototype.didRenderLayout = function (bucket) {
            bucket.finalize();
        };

        OutletComponentManager.prototype.getDestructor = function () {
            return null;
        };

        return OutletComponentManager;
    }(_abstract.default);

    var MANAGER = new OutletComponentManager();

    var TopLevelOutletComponentManager = function (_OutletComponentManag) {
        (0, _emberBabel.inherits)(TopLevelOutletComponentManager, _OutletComponentManag);

        function TopLevelOutletComponentManager() {
            return (0, _emberBabel.possibleConstructorReturn)(this, _OutletComponentManag.apply(this, arguments));
        }

        TopLevelOutletComponentManager.prototype.create = function (environment, definition, _args, dynamicScope) {
            return new StateBucket(dynamicScope.outletState.value());
        };

        TopLevelOutletComponentManager.prototype.layoutFor = function (definition, _bucket, env) {
            return env.getCompiledBlock(TopLevelOutletLayoutCompiler, definition.template);
        };

        return TopLevelOutletComponentManager;
    }(OutletComponentManager);

    var TOP_LEVEL_MANAGER = new TopLevelOutletComponentManager();

    exports.TopLevelOutletComponentDefinition = function (_ComponentDefinition) {
        (0, _emberBabel.inherits)(TopLevelOutletComponentDefinition, _ComponentDefinition);

        function TopLevelOutletComponentDefinition(instance) {

            var _this3 = (0, _emberBabel.possibleConstructorReturn)(this, _ComponentDefinition.call(this, 'outlet', TOP_LEVEL_MANAGER, instance));

            _this3.template = instance.template;
            (0, _emberUtils.generateGuid)(_this3);
            return _this3;
        }

        return TopLevelOutletComponentDefinition;
    }(_runtime.ComponentDefinition);

    var TopLevelOutletLayoutCompiler = function () {
        function TopLevelOutletLayoutCompiler(template) {

            this.template = template;
        }

        TopLevelOutletLayoutCompiler.prototype.compile = function (builder) {
            builder.wrapLayout(this.template);
            builder.tag.static('div');
            builder.attrs.static('id', (0, _emberUtils.guidFor)(this));
            builder.attrs.static('class', 'ember-view');
        };

        return TopLevelOutletLayoutCompiler;
    }();

    TopLevelOutletLayoutCompiler.id = 'top-level-outlet';

    exports.OutletComponentDefinition = function (_ComponentDefinition2) {
        (0, _emberBabel.inherits)(OutletComponentDefinition, _ComponentDefinition2);

        function OutletComponentDefinition(outletName, template) {

            var _this4 = (0, _emberBabel.possibleConstructorReturn)(this, _ComponentDefinition2.call(this, 'outlet', MANAGER, null));

            _this4.outletName = outletName;
            _this4.template = template;
            (0, _emberUtils.generateGuid)(_this4);
            return _this4;
        }

        return OutletComponentDefinition;
    }(_runtime.ComponentDefinition);

    var OutletLayoutCompiler = exports.OutletLayoutCompiler = function () {
        function OutletLayoutCompiler(template) {

            this.template = template;
        }

        OutletLayoutCompiler.prototype.compile = function (builder) {
            builder.wrapLayout(this.template);
        };

        return OutletLayoutCompiler;
    }();

    OutletLayoutCompiler.id = 'outlet';
});