enifed('ember-glimmer/helpers/get', ['exports', 'ember-babel', '@glimmer/reference', '@glimmer/runtime', 'ember-metal', 'ember-glimmer/utils/references'], function (exports, _emberBabel, _reference, _runtime, _emberMetal, _references) {
    'use strict';

    exports.default = function (_vm, args) {
        return GetHelperReference.create(args.positional.at(0), args.positional.at(1));
    };

    var GetHelperReference = function (_CachedReference) {
        (0, _emberBabel.inherits)(GetHelperReference, _CachedReference);

        GetHelperReference.create = function (sourceReference, pathReference) {
            var parts;

            if ((0, _reference.isConst)(pathReference)) {
                parts = pathReference.value().split('.');

                return (0, _reference.referenceFromParts)(sourceReference, parts);
            } else {
                return new GetHelperReference(sourceReference, pathReference);
            }
        };

        function GetHelperReference(sourceReference, pathReference) {

            var _this = (0, _emberBabel.possibleConstructorReturn)(this, _CachedReference.call(this));

            _this.sourceReference = sourceReference;
            _this.pathReference = pathReference;
            _this.lastPath = null;
            _this.innerReference = _runtime.NULL_REFERENCE;
            var innerTag = _this.innerTag = _reference.UpdatableTag.create(_reference.CONSTANT_TAG);
            _this.tag = (0, _reference.combine)([sourceReference.tag, pathReference.tag, innerTag]);
            return _this;
        }

        GetHelperReference.prototype.compute = function () {
            var lastPath = this.lastPath,
                innerReference = this.innerReference,
                innerTag = this.innerTag,
                pathType;

            var path = this.lastPath = this.pathReference.value();
            if (path !== lastPath) {
                if (path !== undefined && path !== null && path !== '') {
                    pathType = typeof path;

                    if (pathType === 'string') {
                        innerReference = (0, _reference.referenceFromParts)(this.sourceReference, path.split('.'));
                    } else if (pathType === 'number') {
                        innerReference = this.sourceReference.get('' + path);
                    }
                    innerTag.inner.update(innerReference.tag);
                } else {
                    innerReference = _runtime.NULL_REFERENCE;
                    innerTag.inner.update(_reference.CONSTANT_TAG);
                }
                this.innerReference = innerReference;
            }
            return innerReference.value();
        };

        GetHelperReference.prototype[_references.UPDATE] = function (value) {
            (0, _emberMetal.set)(this.sourceReference.value(), this.pathReference.value(), value);
        };

        return GetHelperReference;
    }(_references.CachedReference);
});