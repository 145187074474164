enifed('ember-runtime/system/native_array', ['exports', 'ember-metal', 'ember-environment', 'ember-runtime/mixins/array', 'ember-runtime/mixins/mutable_array', 'ember-runtime/mixins/observable', 'ember-runtime/mixins/copyable', 'ember-debug', 'ember-runtime/mixins/freezable', 'ember-runtime/copy'], function (exports, _emberMetal, _emberEnvironment, _array, _mutable_array, _observable, _copyable, _emberDebug, _freezable, _copy) {
  'use strict';

  exports.NativeArray = exports.A = undefined;

  var _NativeArray;

  // Add Ember.Array to Array.prototype. Remove methods with native
  // implementations and supply some more optimized versions of generic methods
  // because they are so common.

  /**
    The NativeArray mixin contains the properties needed to make the native
    Array support Ember.MutableArray and all of its dependent APIs. Unless you
    have `EmberENV.EXTEND_PROTOTYPES` or `EmberENV.EXTEND_PROTOTYPES.Array` set to
    false, this will be applied automatically. Otherwise you can apply the mixin
    at anytime by calling `Ember.NativeArray.apply(Array.prototype)`.
  
    @class Ember.NativeArray
    @uses MutableArray
    @uses Observable
    @uses Ember.Copyable
    @public
  */
  var NativeArray = _emberMetal.Mixin.create(_mutable_array.default, _observable.default, _copyable.default, {
    get: function (key) {
      if ('number' === typeof key) {
        return this[key];
      } else {
        return this._super(key);
      }
    },
    objectAt: function (idx) {
      return this[idx];
    },
    replace: function (idx, amt, objects) {
      false && !!this.isFrozen && (0, _emberDebug.assert)(_freezable.FROZEN_ERROR, !this.isFrozen);
      false && !(objects === null || objects === undefined || Array.isArray(objects)) && (0, _emberDebug.assert)('The third argument to replace needs to be an array.', objects === null || objects === undefined || Array.isArray(objects));

      // if we replaced exactly the same number of items, then pass only the
      // replaced range. Otherwise, pass the full remaining array length
      // since everything has shifted
      var len = objects ? (0, _emberMetal.get)(objects, 'length') : 0;
      (0, _array.arrayContentWillChange)(this, idx, amt, len);

      if (len === 0) {
        this.splice(idx, amt);
      } else {
        (0, _emberMetal.replace)(this, idx, amt, objects);
      }

      (0, _array.arrayContentDidChange)(this, idx, amt, len);
      return this;
    },
    unknownProperty: function (key, value) {
      var ret = void 0; // = this.reducedProperty(key, value);
      if (value !== undefined && ret === undefined) {
        ret = this[key] = value;
      }
      return ret;
    },

    indexOf: Array.prototype.indexOf,
    lastIndexOf: Array.prototype.lastIndexOf,

    copy: function (deep) {
      if (deep) {
        return this.map(function (item) {
          return (0, _copy.default)(item, true);
        });
      }

      return this.slice();
    }
  });

  // Remove any methods implemented natively so we don't override them
  var ignore = ['length'];
  NativeArray.keys().forEach(function (methodName) {
    if (Array.prototype[methodName]) {
      ignore.push(methodName);
    }
  });

  exports.NativeArray = NativeArray = (_NativeArray = NativeArray).without.apply(_NativeArray, ignore);
  /**
    @module @ember/array
  */
  /**
    Creates an `Ember.NativeArray` from an Array-like object.
    Does not modify the original object's contents. `A()` is not needed if
    `EmberENV.EXTEND_PROTOTYPES` is `true` (the default value). However,
    it is recommended that you use `A()` when creating addons for
    ember or when you can not guarantee that `EmberENV.EXTEND_PROTOTYPES`
    will be `true`.
  
    Example
  
    ```app/components/my-component.js
    import Component from '@ember/component';
    import { A } from '@ember/array';
  
    export default Component.extend({
      tagName: 'ul',
      classNames: ['pagination'],
  
      init() {
        this._super(...arguments);
  
        if (!this.get('content')) {
          this.set('content', A());
          this.set('otherContent', A([1,2,3]));
        }
      }
    });
    ```
  
    @method A
    @static
    @for @ember/array
    @return {Ember.NativeArray}
    @public
  */
  var A = void 0;

  if (_emberEnvironment.ENV.EXTEND_PROTOTYPES.Array) {
    NativeArray.apply(Array.prototype);
    exports.A = A = function (arr) {
      return arr || [];
    };
  } else {
    exports.A = A = function (arr) {
      if (!arr) {
        arr = [];
      }
      return _array.default.detect(arr) ? arr : NativeArray.apply(arr);
    };
  }

  _emberMetal.default.A = A;
  exports.A = A;
  exports.NativeArray = NativeArray;
  exports.default = NativeArray;
});