define('liquid-fire/ember-internals/version-specific/index', ['exports', 'ember', 'liquid-fire/templates/version-specific/get-outlet-state'], function (exports, _ember, _liquidFireTemplatesVersionSpecificGetOutletState) {
  exports.initialize = initialize;
  exports.containingElement = containingElement;
  var getViewBounds = _ember['default'].ViewUtils.getViewBounds;

  function initialize() {}

  function containingElement(view) {
    return getViewBounds(view).parentElement;
  }

  Object.defineProperty(exports, 'getOutletStateTemplate', {
    enumerable: true,
    get: function get() {
      return _liquidFireTemplatesVersionSpecificGetOutletState['default'];
    }
  });
});