enifed('ember-runtime/inject', ['exports', 'ember-metal', 'ember-debug'], function (exports, _emberMetal, _emberDebug) {
  'use strict';

  exports.default = inject;
  exports.createInjectionHelper =

  /**
    This method allows other Ember modules to register injection helpers for a
    given container type. Helpers are exported to the `inject` namespace as the
    container type itself.
  
    @private
    @method createInjectionHelper
    @since 1.10.0
    @for Ember
    @param {String} type The container type the helper will inject
    @param {Function} validator A validation callback that is executed at mixin-time
  */
  function (type, validator) {
    typeValidators[type] = validator;

    inject[type] = function (name) {
      return new _emberMetal.InjectedProperty(type, name);
    };
  }

  /**
    Validation function that runs per-type validation functions once for each
    injected type encountered.
  
    @private
    @method validatePropertyInjections
    @since 1.10.0
    @for Ember
    @param {Object} factory The factory object
  */
  ;
  exports.validatePropertyInjections = function (factory) {
    var proto = factory.proto(),
        desc,
        i,
        validator;
    var types = [];

    for (var key in proto) {
      desc = proto[key];

      if (desc instanceof _emberMetal.InjectedProperty && types.indexOf(desc.type) === -1) {
        types.push(desc.type);
      }
    }

    if (types.length) {
      for (i = 0; i < types.length; i++) {
        validator = typeValidators[types[i]];


        if (typeof validator === 'function') {
          validator(factory);
        }
      }
    }

    return true;
  };

  /**
    Namespace for injection helper methods.
  
    @class inject
    @namespace Ember
    @static
    @public
  */
  function inject() {
    false && !false && (0, _emberDebug.assert)('Injected properties must be created through helpers, see \'' + Object.keys(inject).map(function (k) {
      return '\'inject.' + k + '\'';
    }).join(' or ') + '\'');
  }

  // Dictionary of injection validations by type, added to by `createInjectionHelper`
  var typeValidators = {};
});