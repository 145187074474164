enifed('ember-runtime/system/core_object', ['exports', 'ember-utils', 'ember-metal', 'ember-runtime/mixins/action_handler', 'ember-runtime/inject', 'ember-debug'], function (exports, _emberUtils, _emberMetal, _action_handler, _inject, _emberDebug) {
  'use strict';

  exports.POST_INIT = undefined;

  var _Mixin$create, _ClassMixinProps;

  var schedule = _emberMetal.run.schedule;
  var applyMixin = _emberMetal.Mixin._apply;
  var finishPartial = _emberMetal.Mixin.finishPartial;
  var reopen = _emberMetal.Mixin.prototype.reopen;

  var POST_INIT = exports.POST_INIT = (0, _emberUtils.symbol)('POST_INIT');

  function makeCtor() {
    // Note: avoid accessing any properties on the object since it makes the
    // method a lot faster. This is glue code so we want it to be as fast as
    // possible.

    var wasApplied = false;
    var initProperties = void 0,
        initFactory = void 0;

    var Class = function () {
      function Class() {

        if (!wasApplied) {
          Class.proto(); // prepare prototype...
        }

        if (arguments.length > 0) {
          initProperties = [arguments[0]];
        }

        this.__defineNonEnumerable(_emberUtils.GUID_KEY_PROPERTY);
        var m = (0, _emberMetal.meta)(this),
            props,
            concatenatedProperties,
            mergedProperties,
            hasConcatenatedProps,
            hasMergedProps,
            i,
            properties,
            keyNames,
            j,
            keyName,
            value,
            baseValue,
            isDescriptor;
        var proto = m.proto;
        m.proto = this;

        if (initFactory) {
          m.factory = initFactory;
          initFactory = null;
        }
        if (initProperties) {
          // capture locally so we can clear the closed over variable
          props = initProperties;

          initProperties = null;

          concatenatedProperties = this.concatenatedProperties;
          mergedProperties = this.mergedProperties;
          hasConcatenatedProps = concatenatedProperties && concatenatedProperties.length > 0;
          hasMergedProps = mergedProperties && mergedProperties.length > 0;


          for (i = 0; i < props.length; i++) {
            properties = props[i];


            false && !(typeof properties === 'object' || properties === undefined) && (0, _emberDebug.assert)('Ember.Object.create only accepts objects.', typeof properties === 'object' || properties === undefined);
            false && !!(properties instanceof _emberMetal.Mixin) && (0, _emberDebug.assert)('Ember.Object.create no longer supports mixing in other ' + 'definitions, use .extend & .create separately instead.', !(properties instanceof _emberMetal.Mixin));

            if (!properties) {
              continue;
            }

            keyNames = Object.keys(properties);


            for (j = 0; j < keyNames.length; j++) {
              keyName = keyNames[j];
              value = properties[keyName];


              if ((0, _emberMetal.detectBinding)(keyName)) {
                m.writeBindings(keyName, value);
              }

              false && !!(value instanceof _emberMetal.ComputedProperty) && (0, _emberDebug.assert)('Ember.Object.create no longer supports defining computed ' + 'properties. Define computed properties using extend() or reopen() ' + 'before calling create().', !(value instanceof _emberMetal.ComputedProperty));
              false && !!(typeof value === 'function' && value.toString().indexOf('._super') !== -1) && (0, _emberDebug.assert)('Ember.Object.create no longer supports defining methods that call _super.', !(typeof value === 'function' && value.toString().indexOf('._super') !== -1));
              false && !!(keyName === 'actions' && _action_handler.default.detect(this)) && (0, _emberDebug.assert)('`actions` must be provided at extend time, not at create time, ' + 'when Ember.ActionHandler is used (i.e. views, controllers & routes).', !(keyName === 'actions' && _action_handler.default.detect(this)));

              baseValue = this[keyName];
              isDescriptor = baseValue !== null && typeof baseValue === 'object' && baseValue.isDescriptor;


              if (hasConcatenatedProps && concatenatedProperties.indexOf(keyName) > -1) {
                if (baseValue) {
                  value = (0, _emberUtils.makeArray)(baseValue).concat(value);
                } else {
                  value = (0, _emberUtils.makeArray)(value);
                }
              }

              if (hasMergedProps && mergedProperties.indexOf(keyName) > -1) {
                value = (0, _emberUtils.assign)({}, baseValue, value);
              }

              if (isDescriptor) {
                baseValue.set(this, keyName, value);
              } else if (typeof this.setUnknownProperty === 'function' && !(keyName in this)) {
                this.setUnknownProperty(keyName, value);
              } else {
                this[keyName] = value;
              }
            }
          }
        }

        finishPartial(this, m);

        this.init.apply(this, arguments);

        this[POST_INIT]();

        m.proto = proto;
        (0, _emberMetal.finishChains)(m);
        (0, _emberMetal.sendEvent)(this, 'init', undefined, undefined, undefined, m);
      }

      Class.willReopen = function () {
        if (wasApplied) {
          Class.PrototypeMixin = _emberMetal.Mixin.create(Class.PrototypeMixin);
        }

        wasApplied = false;
      };

      Class._initProperties = function (args) {
        initProperties = args;
      };

      Class._initFactory = function (factory) {
        initFactory = factory;
      };

      Class.proto = function () {
        var superclass = Class.superclass;
        if (superclass) {
          superclass.proto();
        }

        if (!wasApplied) {
          wasApplied = true;
          Class.PrototypeMixin.applyPartial(Class.prototype);
        }

        return this.prototype;
      };

      return Class;
    }();

    Class.toString = _emberMetal.Mixin.prototype.toString;

    return Class;
  }

  /**
    @class CoreObject
    @public
  */
  var CoreObject = makeCtor();
  CoreObject.toString = function () {
    return 'Ember.CoreObject';
  };
  CoreObject.PrototypeMixin = _emberMetal.Mixin.create((_Mixin$create = {
    reopen: function () {
      var _len, args, _key;

      for (_len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      applyMixin(this, args, true);
      return this;
    },
    init: function () {}
  }, _Mixin$create[POST_INIT] = function () {}, _Mixin$create.__defineNonEnumerable = function (property) {
    Object.defineProperty(this, property.name, property.descriptor);
    //this[property.name] = property.descriptor.value;
  }, _Mixin$create.concatenatedProperties = null, _Mixin$create.mergedProperties = null, _Mixin$create.isDestroyed = (0, _emberMetal.descriptor)({
    get: function () {
      return (0, _emberMetal.peekMeta)(this).isSourceDestroyed();
    },
    set: function (value) {
      // prevent setting while applying mixins
      if (value !== null && typeof value === 'object' && value.isDescriptor) {
        return;
      }

      false && !false && (0, _emberDebug.assert)('You cannot set `' + this + '.isDestroyed` directly, please use `.destroy()`.', false);
    }
  }), _Mixin$create.isDestroying = (0, _emberMetal.descriptor)({
    get: function () {
      return (0, _emberMetal.peekMeta)(this).isSourceDestroying();
    },
    set: function (value) {
      // prevent setting while applying mixins
      if (value !== null && typeof value === 'object' && value.isDescriptor) {
        return;
      }

      false && !false && (0, _emberDebug.assert)('You cannot set `' + this + '.isDestroying` directly, please use `.destroy()`.', false);
    }
  }), _Mixin$create.destroy = function () {
    var m = (0, _emberMetal.peekMeta)(this);
    if (m.isSourceDestroying()) {
      return;
    }

    m.setSourceDestroying();

    schedule('actions', this, this.willDestroy);
    schedule('destroy', this, this._scheduledDestroy, m);

    return this;
  }, _Mixin$create.willDestroy = function () {}, _Mixin$create._scheduledDestroy = function (m) {
    if (m.isSourceDestroyed()) {
      return;
    }
    (0, _emberMetal.deleteMeta)(this);
    m.setSourceDestroyed();
  }, _Mixin$create.bind = function (to, from) {
    if (!(from instanceof _emberMetal.Binding)) {
      from = _emberMetal.Binding.from(from);
    }
    from.to(to).connect(this);
    return from;
  }, _Mixin$create.toString = function () {
    var hasToStringExtension = typeof this.toStringExtension === 'function';
    var extension = hasToStringExtension ? ':' + this.toStringExtension() : '';

    var ret = '<' + (this[_emberUtils.NAME_KEY] || (0, _emberMetal.peekMeta)(this).factory || this.constructor.toString()) + ':' + (0, _emberUtils.guidFor)(this) + extension + '>';

    return ret;
  }, _Mixin$create));

  CoreObject.PrototypeMixin.ownerConstructor = CoreObject;

  CoreObject.__super__ = null;

  var ClassMixinProps = (_ClassMixinProps = {

    ClassMixin: _emberMetal.REQUIRED,

    PrototypeMixin: _emberMetal.REQUIRED,

    isClass: true,

    isMethod: false
  }, _ClassMixinProps[_emberUtils.NAME_KEY] = null, _ClassMixinProps[_emberUtils.GUID_KEY] = null, _ClassMixinProps.extend = function () {
    var Class = makeCtor();
    var proto = void 0;
    Class.ClassMixin = _emberMetal.Mixin.create(this.ClassMixin);
    Class.PrototypeMixin = _emberMetal.Mixin.create(this.PrototypeMixin);

    Class.ClassMixin.ownerConstructor = Class;
    Class.PrototypeMixin.ownerConstructor = Class;

    reopen.apply(Class.PrototypeMixin, arguments);

    Class.superclass = this;
    Class.__super__ = this.prototype;

    proto = Class.prototype = Object.create(this.prototype);
    proto.constructor = Class;
    (0, _emberUtils.generateGuid)(proto);
    (0, _emberMetal.meta)(proto).proto = proto; // this will disable observers on prototype

    Class.ClassMixin.apply(Class);
    return Class;
  }, _ClassMixinProps.create = function () {
    var C = this,
        _len2,
        args,
        _key2;

    for (_len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    if (args.length > 0) {
      this._initProperties(args);
    }
    return new C();
  }, _ClassMixinProps.reopen = function () {
    this.willReopen();
    reopen.apply(this.PrototypeMixin, arguments);
    return this;
  }, _ClassMixinProps.reopenClass = function () {
    reopen.apply(this.ClassMixin, arguments);
    applyMixin(this, arguments, false);
    return this;
  }, _ClassMixinProps.detect = function (obj) {
    if ('function' !== typeof obj) {
      return false;
    }
    while (obj) {
      if (obj === this) {
        return true;
      }
      obj = obj.superclass;
    }
    return false;
  }, _ClassMixinProps.detectInstance = function (obj) {
    return obj instanceof this;
  }, _ClassMixinProps.metaForProperty = function (key) {
    var proto = this.proto();
    var possibleDesc = proto[key];

    false && !(possibleDesc !== null && typeof possibleDesc === 'object' && possibleDesc.isDescriptor) && (0, _emberDebug.assert)('metaForProperty() could not find a computed property with key \'' + key + '\'.', possibleDesc !== null && typeof possibleDesc === 'object' && possibleDesc.isDescriptor);

    return possibleDesc._meta || {};
  }, _ClassMixinProps._computedProperties = (0, _emberMetal.computed)(function () {
    (0, _emberMetal._hasCachedComputedProperties)();
    var proto = this.proto();
    var property = void 0;
    var properties = [];

    for (var name in proto) {
      property = proto[name];

      if (property !== null && typeof property === 'object' && property.isDescriptor) {
        properties.push({
          name: name,
          meta: property._meta
        });
      }
    }
    return properties;
  }).readOnly(), _ClassMixinProps.eachComputedProperty = function (callback, binding) {
    var property = void 0,
        i;
    var empty = {};

    var properties = (0, _emberMetal.get)(this, '_computedProperties');

    for (i = 0; i < properties.length; i++) {
      property = properties[i];
      callback.call(binding || this, property.name, property.meta || empty);
    }
  }, _ClassMixinProps);

  var ClassMixin = _emberMetal.Mixin.create(ClassMixinProps);

  ClassMixin.ownerConstructor = CoreObject;

  CoreObject.ClassMixin = ClassMixin;

  ClassMixin.apply(CoreObject);
  exports.default = CoreObject;
});